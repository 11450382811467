export default {
  text: '#432818',
  primary: '#6F1D1B',
  primaryLight: '#FFE6A7',
  primaryDark: '#CB997E',
  secondary: '#BB9457',
  secondaryLight: '#b8a17f',
  secondaryDark: '#876b42',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303',
};
