export default {
  primary: {
    backgroundColor: 'primaryDark',
    color: 'light',
    border: 'solid 2px',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    borderColor: 'primaryDark',
    transition: 'all .25s',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      transition: 'all .25s',
    },
  },
  secondary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'white',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    borderRadius: '3px',
    transition: 'all .25s',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      borderColor: 'primary',
      transition: 'all .25s',
      color: 'primary',
    },
  },
};
