export default {
  heading: 'Satisfy, serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Open Sans, serif',
  googleFonts: ['Open Sans:400,500,600,700,800', 'Satisfy:400'],
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
};
