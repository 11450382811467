export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        filter: 'unset',
        maxHeight: ['70px', '90px', '120px', ''],
        // maxWidth: ['', '', 'unset', 'unset'],
      },
    },
    '.logoScrolled': {
      img: {
        filter: 'brightness(0)',
        maxHeight: ['70px', '90px', '90px', ''],
      },
    },

    '.container': {
      '.smallNavMenu': {
        '.navItem': {
          color: 'white',
          a: {
            fontSize: '1rem',
            textTransform: 'capitalize',
            ':hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
    },
    '.containerScrolled': {
      boxShadow: '0 0 12px rgba(0,0,0,.3)',
      border: 'none',
      '.smallNavMenu': {
        '.navItem': {
          color: 'dark',
          a: {
            textTransform: 'capitalize',
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      '.hamburger': {
        backgroundColor: `rgba(0,0,0,0)`,
        '>div': {
          backgroundColor: 'primary',
        },
      },
    },

    '.hamburger': {
      borderRadius: '10px',
      backgroundColor: 'transparent',
      borderColor: 'none',
      border: 'none',
      backgroundColor: `rgba(0,0,0,.5)`,
      '> div': {
        backgroundColor: 'white',
      },
    },
    '.hamburgerOpen': {
      border: 'none',
    },
    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem',
      },
    },

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'secondary',
      a: {
        textTransform: 'capitalize',
        color: 'white',
        '&:hover': {
          color: 'primary',
        },
      },
      '.navMenuLogo': {
        maxHeight: '150px',
        marginBottom: '1.5rem',
        borderBottom: '1px solid',
        paddingBottom: '1.5rem',
        display: 'flex',
        // filter: 'brightness(0)',
        img: {
          maxHeight: '150px',
          width: 'auto',
          objectFit: 'contain',
        },
        ':hover': {
          // transform: 'rotate(360deg)',
        },
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
        backgroundColor: 'white',
        borderColor: 'white',
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
      },

      '.socialIconsContainer': {
        margin: '0rem 1rem',
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right',
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
    },
  },

  footer: {
    maxWidth: 'none',
    padding: ['2rem 1rem 6rem', '', '2rem'],
    '.logo': {
      filter: 'brightness(0)',
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.secondary',
      },
    },
    '.gonationLogo': {
      width: 'fit-content',
    },
    '.copyright': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem',
      '::after': {
        content: "'Photos By Walker Studios LLC'",
        fontSize: '0.8rem',
        opacity: '0.8',
        textAlign: 'center',
      },
    },
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primaryDark',
    order: '1',
  },
  subtitle: {
    color: 'secondaryDark',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.25rem', '1.5rem', '1.75rem'],
    marginBottom: '1.5rem',
    order: '2',
    // fontFamily: 'display',
    fontWeight: 'bold',
    fontFamily: 'body',

    border: 'none',
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      textAlign: 'left',
      fontSize: 'inherit',
    },
  },

  sideBySide1: {
    padding: '0rem 1rem 1rem',
    '.lazyload-wrapper': {
      maxHeight: '75vh',
    },
    '.content': {
      // variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start',
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.imageContainer': {
      height: ['', '', '75vh'],
    },
    '.content': {
      // variant: 'customVariants.borderCorners',
      order: '2',
      position: 'relative',
      alignItems: 'flex-start',
    },
    a: {
      order: '4',
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    marginTop: '1rem',
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem',
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      color: 'primaryLight',
      textShadow: '1px 1px 20px black',
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center',
      },
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepageMenu: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },

  homepageSoups: {
    // backgroundColor: 'primaryLight',
    margin: '0rem 1rem 1rem',
    width: 'calc(100% - 2rem)',
    padding: ['3rem 1rem', '3rem 2rem', '3rem', '4rem'],
    maxWidth: 'unset',

    '.innerMenuContainer': {
      maxWidth: '1000px',
      margin: '0rem auto',
      position: 'relative',
    },
    '.slick-slider': {
      position: 'static',
      borderTop: 'solid 1px',
      borderColor: 'secondaryDark',
      padding: '2rem 0rem 0rem',
      '.slick-arrow': {
        width: '40px',
        height: '40px',
        backgroundColor: 'secondaryDark',
        padding: '0.5rem',
        color: 'white',
        left: 'unset',
        bottom: 'unset',
        top: '0rem',
        right: '0rem',
        transform: 'unset',
        ':hover': {
          opacity: '0.7',
        },
      },
      '.slick-prev': {
        right: '3rem',
      },
      '.slick-next': {},
      '.slick-dots li.slick-active button:before': {
        color: 'secondaryDark',
      },

      '.slick-dots li button:before': {
        fontSize: '20px',
      },
    },
    '.menuSectionTitle': {
      width: '80%',
      textAlign: 'left',
      margin: '0rem 0rem 2rem',
      fontSize: ['2rem', '2.5rem', '2.75rem', '3rem'],
      color: 'primaryDark',
    },

    '.menuItemInnerContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '.menuItemImageContainer': {
        width: '30%',
        img: {
          borderRadius: '10px',
        },
      },
      '.menuItemContentContainer': {
        width: '70%',
        padding: ['2rem', '', '3rem', '4rem'],
        '.menuItemName': {
          fontSize: ['2rem', '', '3rem'],
          color: 'primaryDark',
        },
        '.menuItemDescription': {
          color: 'secondaryDark',
          fontSize: '1.1rem',
        },
      },
    },
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide2',
  },

  homepageContact: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: '#008C45',
      fontFamily: 'body',
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      color: '#fff',
      backgroundColor: '#CD212B',
    },
    '.date': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      backgroundColor: 'white',
      color: 'text',
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background',
    },
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1',
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1',
  },
  venueBar: {
    variant: 'customVariants.sideBySide1',
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      backgroundColor: 'none',
      color: 'primary',
      fontFamily: 'heading',
      padding: '0.5rem',
      background: 'none',
      border: 'none',
      textTransform: 'none',
    },
    '.albumsCTAActive': {
      textTransform: 'auto',
      textDecoration: 'auto',
      '.albumTitle': {
        fontSize: ['1.5rem', '1.5rem', '2rem', '2.5rem'],
        textTransform: 'none',
      },
    },
    '.albumName': {
      textTransform: 'none',
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2',
      },
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset',
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        // filter: 'brightness(0) invert(1)',
      },
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%',
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary',
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      color: 'white',
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white',
      },
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto',
    },
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem',
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      color: 'white',
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem',
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem'],
      },
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
    },
  },

  // ? ========================
  // ? ====  Catering page  ===
  // ? ========================

  cateringText: {
    background: 'unset',
    backgroundColor: 'primary',
    '.title': {
      color: 'white',
      marginBottom: '1.5rem',
      fontFamily: 'body',
    },
    '.subtitle': {
      color: 'white',
    },
    '.text': {
      color: 'white',
      p: {
        color: 'white',
      },
      li: {
        color: 'white',
      },
    },
    '.ctaButton': {
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    },
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black',
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem',
      },
    },
  },

  pressPage: {
    backgroundColor: 'background',
  },
}
